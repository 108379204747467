import axios from "axios";
import helper from "./helper";

const getAxios = (endpoint, params) => {
  return axios.get(endpoint, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("jwt"),
      "c-time": helper.getDateTime(),
      "x-api-key": helper.sha256(
        process.env.VUE_APP_API_KEY + helper.getDateTime()
      ),
    },
    params: params,
  });
};

export default getAxios;
